<template>
  <div>
    <van-nav-bar title="渠道列表" />
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      success-text="刷新成功"
      style="min-height: 100vh"
    >
      <div>
        <!-- <div class="header-info">
        <div class="quantity">15<span style="font-size: 42px">人</span></div>
        <div class="more">
          <img src="http://192.168.8.183/img/无敌狗子.jpg" />
          <img src="http://192.168.8.183/img/无敌狗子.jpg" />
          <img src="http://192.168.8.183/img/无敌狗子.jpg" />
          <img
            src="https://t.yhaocang.com/upload/sf_static/img/tabbar/channel/more.png"
          />
        </div>
      </div>
      <div class="total">
        <div>渠道商:(总人数)</div>
        <div>总佣金:￥123</div>
      </div> -->
        <!-- <div class="gap"></div> -->
        <van-empty description="暂无记录" v-if="showempty" />
        <div class="list">
          <van-collapse accordion v-model="activeName">
            <div class="item" v-for="(val, key) in dataList" :key="key">
              <div class="top">
                <img src="@/assets/touxiang.png" />
                <div class="center">
                  <div class="id" v-if="key != 0">{{ key }}</div>
                  <div class="id" v-else>自营收益</div>
                  <div class="time">{{ val.add_time }}</div>
                </div>
                <div class="amount">￥{{ val.total_profit }}</div>
              </div>
              <!-- <van-collapse-item :name="key" style="background: #fefaf2">
                <div slot="title" class="title">
                  <div class="form">
                    <div>月:{{ val.month }}张</div>
                    <div>季:{{ val.season }}张</div>
                    <div>年:{{ val.year }}张</div>
                  </div>
                  <div class="amount">￥{{ val.total_profit }}</div>
                </div>
              </van-collapse-item> -->
              <van-collapse-item :name="key" style="background: #fefaf2">
                <div slot="title" class="title">
                  <div class="form">
                    <div>月:{{ val.month }}张</div>
                    <div>季:{{ val.season }}张</div>
                    <div>年:{{ val.year }}张</div>
                  </div>
                </div>
                <div slot="right-icon" class="unfold">
                  {{ activeName == key ? "收起" : "展开" }}
                </div>
                <div
                  class="unfold-item"
                  v-for="item in val.list"
                  :key="item.id"
                >
                  <div>{{ item.add_time }}</div>
                  <div>{{ item.title }}</div>
                  <div class="cardNumber">+{{ item.profit }}</div>
                </div>
              </van-collapse-item>
            </div>
          </van-collapse>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { profitList } from "@/api/channel";

export default {
  data() {
    return {
      activeName: "",
      page: 1,
      showempty: false,
      isLoading: false,
      loading: false,
      finished: false,
      dataList: {}
    };
  },
  created() {
    this.GetprofitList();
  },
  methods: {
    onLoad() {
      this.GetprofitList();
    },
    async onRefresh() {
      this.page = 1;
      this.dataList = [];
      this.finished = false;
      this.loading = true;
      await this.GetprofitList();
      this.isLoading = false;
    },
    async GetprofitList() {
      let res = await profitList({
        page: this.page
      });
      console.log(res);
      if (res.data.code == 200) {
        ++this.page;
        this.dataList = res.data.data;
        if (this.dataList.length >= 0 && res.data.data.length <= 0) {
          this.showempty = true;
        } else {
          this.showempty = false;
        }
        if (this.dataList.length > 0 && res.data.data == []) {
          // this.finished = true;
        }
      }
    }
  },
  mounted() {
    // console.log(Object.keys(this.dataList));
    let keysArr = Object.keys(this.dataList);
    console.log(keysArr);
    for (let index = 0; index < keysArr.length; index++) {
      console.log(this.dataList[keysArr[index]]);
    }
  }
};
</script>

<style lang="scss" scoped>
.header-info {
  display: flex;
  padding: 0 31px;
  .quantity {
    padding-top: 20px;
    font-size: 150px;
    font-weight: 400;
    color: #333333;
  }
  .more {
    margin-left: auto;
    display: flex;
    align-items: flex-end;
    img {
      box-sizing: border-box;
      width: 73px;
      height: 73px;
      border-radius: 50%;
      border: 6px solid #fff;
      margin-left: -30px;
    }
    img:nth-last-child(1) {
      border: 0;
    }
    // .btn {
    //   width: 73px;
    //   height: 73px;
    //   border-radius: 50%;
    //   border: 6px solid #fff;
    //   margin-left: -30px;
    //   background-color: red;
    // }
  }
}
.total {
  display: flex;
  justify-content: space-between;
  padding: 0 31px;
  font-size: 28px;
  font-weight: 600;
  color: #999999;
  line-height: 97px;
}
.gap {
  background-color: #f2f2f3;
  height: 20px;
}
.list {
  padding: 0 31px;
  .item {
    padding: 31px 0;
    border-bottom: 1px solid #dcdcdc;
    .top {
      display: flex;
      align-items: center;
      img {
        width: 87px;
        height: 87px;
        border-radius: 50%;
      }
      .center {
        margin-left: 20px;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-around;
        line-height: 43px;
        .id {
          font-size: 31px;
          font-weight: 600;
          color: #333333;
          line-height: 43px;
          text-align: left;
        }
        .time {
          line-height: 28px;
          font-size: 22px;
          font-weight: 300;
          color: #999999;
          line-height: 43px;
        }
      }
      .amount {
        font-size: 27px;
        font-weight: 600;
        color: #333333;
        margin-left: auto;
      }
      .amount::first-letter {
        font-size: 22px;
      }
    }
    .title {
      // background-color: #f9f9f9;
      width: 100%;
      line-height: 76px;
      display: flex;
      align-items: center;
      .form {
        display: flex;
        width: 80%;
        justify-content: space-around;
      }
    }
    .unfold {
      width: 103px;
      height: 49px;
      border: 1px solid #999999;
      border-radius: 24px;
      text-align: center;
      line-height: 49px;
      font-size: 21px;
      font-weight: 400;
      color: #6b6b6b;
      margin-left: auto;
      margin-right: 10px;
    }
    .unfold-item {
      display: flex;
      justify-content: space-between;
      width: 235px;
      font-size: 25px;
      font-weight: 400;
      color: #333;
      width: 100%;
      line-height: 80px;
      padding: 20px;
      background-color: #fefaf2;
      .cardNumber {
        color: #fd560d;
        width: 100px;
        text-align: center;
        font-size: 25px;
        font-weight: 500;
      }
    }
  }
}
/deep/.van-collapse-item__content {
  padding: 0;
}
/deep/.van-cell {
  padding: 0;
  background-color: #f9f9f9;
  // height: 76px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
</style>
