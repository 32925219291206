import { axios } from "./../plugins/axios";
export function profitList(parameter) {
  return axios.request({
    method: "get",
    params: {
      module: "fans",
      action: "profit",
      app: "profit_list",
      ...parameter
    }
  });
}
